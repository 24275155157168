const subheadingData = {
  1: [
    {
      title: "About Me",
      content: (
        <>
            <p>
              Hello! I'm Aiden,
            </p>
            <p>
              a Graduate Full-stack developer 
              with a passion for developing scalable web applications 
              and working across the full stack.
            </p>
            <p>
              
            </p>
            <p></p>
            <p>
              My expertise lies in creating seamless and intuitive user experiences.
              I specilize in using cutting-edge technologies
              and graphic design skills to produce high quality
              and creative web applications.
            </p>
        </>
      )
    }
  ],

  2: [
    {
      title: "2023",
      content: (
        <>
          <p>
            Professional Year Program Certificate
          </p>
          <p>
            - Performance Education
          </p>
        </>
      )
    },

    {
      title: "2020",
      content: (
        <>
          <p>
            Bachelor of Information Technology (Software Development)
          </p>
          <p>
            - University of South Australia
          </p>
        </>
      )
    },

    {
      title: "2018",
      content: (
        <>
          <p>
            Diploma of Information Technology
          </p>
          <p>
            - South Australian Institute of Business & Technology
          </p>
        </>
      )
    }
  ],

  3: [
    {
      title: "2024 Frontend Developer",
      content: (
        <>
          <h4>
            ChingLam Foods (South Australia)
          </h4>
          <p>
           - Built a web application to manage stock (import, export) for the company
          </p>
          <p>
            - Used TypeScript, ReactJS, Ant Design, PostgreSQL
          </p>
          <p>
            - Users can search for, filter, scan, add, edit, delete orders or items of the orders in the database
          </p>
        </>
      )
    },
    {
      title: "2023 Software Developer Intern",
      content: (
        <>
          <h4>
            GaP Solutions (Edwardstown, South Australia)
          </h4>
          <p>
            - Rebuilt a company project - QuickTicket with NodeJS, MySQL, TypeScript, JavaScript
          </p>
          <p>
            - Debugged and updated outdated code and dependencies, then redesigned to make it compatible with all updates.
          </p>
          <p>
            - Redesigned the database connection of company applications with their database, using C#, Windows Form and Tabs
          </p >
        </>
      )
    },

    {
      title: "2021 Full-stack Developer",
      content: (
        <>
          <h4>
            Vietan Logistics (Vietnam)
          </h4>
          <p>
            - Developed a web application to manage the company's logistics services
          </p>
          <p>
            - Redesigned the company’s web application written in PHP, CSS, HTML, JavaScript, and MySQL
          </p>
          <p>
            - Designed logos, banners, posters and created content, work on a marketing campaign on the app, Facebook, and Instagram
          </p>
        </>
      )
    }
  ]
}

export default subheadingData